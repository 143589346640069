<template>

    <footer class="bg-mio text-center text-white">
  <!-- Grid container -->
  <div class="container p-4 pb-0">
    <!-- Section: Social media -->
    <section class="mb-4">
      <!-- Facebook -->
      <a class="btn border-mio btn-floating m-1" href="https://www.facebook.com/SpinningTangosLyon" role="button" target="_blank"
        ><i class="fab fa-facebook-f col-mio"></i
      ></a>

      <!-- Instagram -->
      <a class="btn border-mio btn-floating m-1 " href="https://www.instagram.com/spinning_tangos/?fbclid=IwAR3ytdrYFHF5tOqNpM7mO6lDgSYzUepEeiUTyP1cIP8JOEt6BuTYcnJbnFQ"
                            target="_blank" role="button"
        ><i class="fab fa-instagram col-mio"></i
      ></a>

      <!-- Soundcloud -->
      <a class="btn border-mio btn-floating m-1" href="https://soundcloud.com/spinningtangos/sets" target="_blank" role="button"
        ><i class="fab fa-soundcloud col-mio"></i
      ></a>

    </section>
    <!-- Section: Social media -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3 col-mio" style="background-color: rgba(0, 0, 0, 0.2);">
    website powered by
    <a class="text-white grassetto" href="mailto:gabriele.capocelli@gmail.com">Gabbo</a>
  </div>
  <!-- Copyright -->
</footer>

</template>

<script>

export default {

    name:'FooTer'


}


</script>

<style scoped>
    .bg-mio {
        background-color: #221f20;
    }
    .col-mio{
         color: #bb9342;
    }

    .border-mio{
        border-color: #bb9342;
    }

    .grassetto{
        font-weight: bolder;
    }
</style>