<template>


    <div id="home">
        <div id="z1">
            <h1 id="h1Home"> WEEKEND AL BACIO</h1>
            <h2 id="h2Home">30/09 - 02/10 <br>2022</h2>
            <p id="pHome" v-html="$t('bacio')"></p>
            <div id="banner">
                <p v-html="$t('form.tangopartner')"></p>
                <img src="../assets/static/img/tangopartner.png" height="125px" id="bannerPic">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeView"
}


</script>
