import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)
const routes = [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/about_us',
        name: 'about_us',
        component: () => import('../views/AboutUsView.vue')
      },
      {
        path: '/djs',
        name: 'our_djs',
        component: () => import('../views/DjsView.vue')
      },
      {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import('../views/SubScribe.vue')
      },
      {
        path: '/thank_you',
        name: 'thank_you',
        component: () => import('../views/ThankYou.vue')
      },
      {
        path: '/info',
        name: 'info',
        component: () => import('../views/InfoView.vue')
      },
      {
        path: '/error400',
        name: 'error400',
        component: () => import('../views/OooupsView.vue')
      }
]

const router = new VueRouter({
  routes,
  linkExactActiveClass: "active"
})

export default router
