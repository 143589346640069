<template>

    <select data-width="fit" id="lang" v-model="$i18n.locale">
        <option v-for="(lang, idx) in $i18n.availableLocales" :key="idx" :value="lang"> <button
                @click="switchLang(lang)"> {{ lang.toUpperCase() }}</button> </option>
    </select>


</template>

<script>

import i18n from "@/i18n"
export default {
    name: 'LaguageSelector',
    methods: {
        switchLang(lang) {
            i18n.locale = lang
        }
    }
}

</script>
