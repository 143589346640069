<template>
  <nav class="navbar navbar-expand-lg static-top navbar-fixed-top navST" style="background-color: rgba(0, 0, 0, 0.2);">
    <div class="container-fluid">
      <div class="navbar-brand">
        <router-link to="/" class="nav-link linkST"><img src="../assets/static/img/logo.png" alt="" height="100"></router-link>
      </div>
      <button @click="hambMenu" class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="fas fa-bars linkST"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav ms-auto">
          <li @click="collapse">
            <router-link to="/" class="nav-link linkST">{{ $t('nav.home') }}</router-link>
          </li>
          <li @click="collapse">
            <router-link to="/djs" class="nav-link linkST">{{ $t('nav.dj') }}</router-link>
          </li>
          <li @click="collapse">
            <router-link to="/info" class="nav-link linkST">INFO</router-link>
          </li>
          <li @click="collapse">
            <router-link to="/about_us" class="nav-link linkST">{{ $t('nav.about') }}</router-link>
          </li>
          <li @click="collapse">
            <router-link to="/subscribe" class="nav-link linkST">{{ $t('nav.subscribe') }}</router-link>
          </li>
        </ul>

      </div>
    </div>
    <span style="white-space:nowrap">{{$t('choose')}}</span>
    <LanguageSelector style="margin-left: 5px;"/>
  </nav>
</template>

<script>

import LanguageSelector from './LanguageSelector.vue'


export default {
  name: "NavBar",
  methods: {
    hambMenu() {
      let menu = document.getElementById("navbarNavAltMarkup")
      menu.classList.toggle("collapse");
    },
    collapse() {
      let menu = document.getElementById("navbarNavAltMarkup")
      menu.classList.add("collapse")
    }
  },
  components: { LanguageSelector }
}
</script>


<style scoped>
.navST {
  background-color: #221f20;
  display: flex;
  justify-content: space-between;
}

.linkST {
  color: #bb9342;
  white-space: nowrap;
}

.active {
  color: white;
}

@media screen and (max-width:380px) {
  #lang {
    margin-left: 0;
  }

  #navbarNavAltMarkup {
    text-align: center;
  }
}
</style>
