<template>
  <div id="app" >
    <NavBar/>
    <router-view/>
    <BottoneUp/>
    <FooTer/>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue"
import FooTer from "./components/FooTer.vue"
import BottoneUp from "./components/BottoneUp.vue"
export default {
  components: {
    NavBar,
    FooTer,
    BottoneUp
  }, data:()=>({
    
  })
}

</script>

<style>
@import './assets/static/fa/css/all.css';
@import './assets/static/css/main.css';

</style>