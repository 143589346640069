<template>

    <div id="top">
        <i class="fas fa-chevron-up fa-2x" @click="toppp"></i>
    </div>

</template>

<script>

export default {
    name: "BottoneUp",
    methods: {
        toppp() {
            window.scrollTo({ top: 0, left: 100, behavior: 'smooth' })
        }
    }
}

</script>